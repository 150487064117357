export const MAIN_API = {
  proxy: process.env.REACT_APP_GATEWAY_URL || '',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND
    ? `http://${window.location.hostname}:8000`
    : process.env.REACT_APP_GATEWAY_URL,
  version: '0.0.1.20200408.1',
}

export const PORTAL_URL = {
  url: process.env.REACT_APP_PORTAL_URL,
}

export const REACT_APP_REALM = {
  realm: process.env.REACT_APP_REALM,
}

export const SUBDOMAIN = window.location.hostname.split('.')[0]

export default MAIN_API
