import { ReactElement } from 'react'

import { ReactComponent as WarningTriangle } from 'assets/icons/warning.svg'

import terms from 'terms.json'
import SimpleButton from 'components/SimpleButton/SimpleButton'
import './AccessDenied.scss'

export default function AccessDenied(): ReactElement {
  return (
    <div
      id="access-denied"
      className="d-flex w-100 justify-content-center align-items-center"
    >
      <div className="d-flex h-50 w-50 justify-content-center align-items-center text-center flex-column">
        <WarningTriangle className="warning-icon" />
        <span className="main-message">
          {terms.Error.forbidden.appTitle}
        </span>
        <br />
        {terms.Error.forbidden.appSubtitle}
      </div>
      <div className="button-wrapper">
        <SimpleButton
          title={terms.Error.forbidden.exitButton}
          onClick={() => window.location.replace('https://dgexsol.fr')}
        />
      </div>
    </div>
  )
}
